import React, { ReactElement } from "react"
import { Helmet } from "react-helmet"
import Button from "../Button/Button"
import Input from "../Input/Input"
import HubspotForm from "react-hubspot-form"

const MailChimpForm = ({ dark }: { dark?: boolean }) => {
  return (
    <div className="mt-20 w-full">
      <form
        action="https://lawlite.us7.list-manage.com/subscribe/post?u=6b76965a7c3566578e4286da5&amp;id=105737d1b7"
        method="post"
        id="mc-embedded-subscribe-form"
        name="mc-embedded-subscribe-form"
        className="validate"
      >
        <div className="grid grid-cols-1 gap-4 w-full max-w-[300px] mx-auto">
          <Input
            type="email"
            name="EMAIL"
            className="required email"
            id="mce-EMAIL"
            required
            placeholder="Email"
          />

          <Input
            type="text"
            name="FNAME"
            className="required"
            id="mce-FNAME"
            required
            placeholder="First Name"
          />

          <Input
            type="text"
            name="MMERGE2"
            className="required"
            id="mce-MMERGE2"
            required
            placeholder="Last Name"
          />
          <div id="mce-responses" className="clear">
            <div
              className="response"
              id="mce-error-response"
              style={{ display: "none" }}
            ></div>
            <div
              className="response"
              id="mce-success-response"
              style={{ display: "none" }}
            ></div>
          </div>

          <div
            style={{ position: "absolute", left: "-5000px" }}
            aria-hidden="true"
          >
            <input
              type="text"
              name="b_6b76965a7c3566578e4286da5_105737d1b7"
              tabIndex={-1}
            />
          </div>
          <div className="clear">
            <Button
              id="mc-embedded-subscribe"
              className={`w-full text-xl ${
                dark ? "!bg-br-primary-dark-blue" : ""
              }`}
            >
              Subscribe to our newsletter
            </Button>
          </div>
        </div>
      </form>
    </div>
  )
}
export default function Subscribe({
  title = "Don't miss out!",
  dark = false,
}: {
  title?: string
  dark?: boolean
}): ReactElement {
  return (
    <div
      className={`min-h-[40vh] flex items-center  flex-col  text-center px-4 pb-20 bg-br-primary-blue ${
        dark ? "text-white" : "text-br-primary-blue  bg-opacity-5"
      }`}
    >
      <h2 className="text-2xl lg:text-4xl mt-10">{title}</h2>
      <h3 className="text-xl lg:text-2xl mt-20 font-bold">
        Get the latest news and updates delivered to your inbox
      </h3>
      <div className="mt-10"></div>
      <HubspotForm
        portalId="19910559"
        formId="4dbd6ab1-7c0e-45a3-8f06-0fb81cc60256"
        onSubmit={() => console.log("Submit!")}
        loading={<div>Loading...</div>}
        css="dsd"
      />
    </div>
  )
}
