import React, { ReactElement } from "react"
import Button, { ButtonLink } from "../Button/Button"

export const GetStarted = ({ invert = false }: { invert?: boolean }) => (
  <>
    <h2 className="text-lg md:text-2xl font-bold mt-4">Free account</h2>
    <ButtonLink
      to="/sign-up"
      className="font-bold text-xl mt-3 !px-8 mb-4"
      invert={invert}
    >
      Get Started
    </ButtonLink>
  </>
)

export const FreeAccount = () => {
  return (
    <>
      {" "}
      <h2 className="text-lg md:text-2xl font-bold mt-4">
        Get started with a Free Account.
      </h2>
      <h2 className="text-lg md:text-2xl font-bold">
        No Obligations. No Credit Card. Keep Your Work.
      </h2>
    </>
  )
}

export default function Banner(): ReactElement {
  return (
    <div className="min-h-[80vh] flex items-center justify-center flex-col text-br-primary-blue text-center px-4">
      <h1 className="text-2xl md:text-4xl font-bold pb-4">
        Next generation tools for next level results.
      </h1>

      <p className="text-lg md:text-2xl mt-5 max-w-[550px] pb-6">
        Removing the bottlenecks and barriers in litigation so you can do your
        best work.
      </p>
      <GetStarted />
      <FreeAccount />
      <p className="text-base md:text-2xl mt-8 mb-10  text-center ">
        LawLite is building a case preparation engine room. <br />A powerful
        intuitive suite of tools for everyday litigation practice.
      </p>
    </div>
  )
}
